export const blockElementTypes = [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'div',
    'blockquote',
    'pre',
    'code-block',
    'details',
    'ul',
    'ol',
    'table',
    'hr',
];
